.highlight,.highlight pre,.highlight table { background:#383838 !important;color:#656555 !important; }
.highlight .hll { background-color:#656555 !important; }
.highlight .err { color:#f0dfaf !important;background-color:#6f6f6f !important; }
.highlight .cs { color:#dca3a3 !important;background-color:#8c5353 !important; }
.highlight .nf { color:#93e0e3 !important; }
.highlight .c,.highlight .cm,.highlight .c1 { color:#5c888b !important; }
.highlight .g,.highlight .l,.highlight .x,.highlight .ge,.highlight .gs,.highlight .ld,.highlight .ni,
.highlight .nl,.highlight .nx,.highlight .py,.highlight .n,.highlight .go,
.highlight .h { color:#6ca0a3 !important; }
.highlight .k { color:#f0dfaf !important; }
.highlight .s1 { color:#bc8383 !important; }
.highlight .nb,.highlight .bp { color:#8cd0d3 !important; }
.highlight .nc,.highlight .nn { color:#7cb8bb !important; }
.highlight .o,.highlight .p,.highlight .na,.highlight .ne { color:#dcdccc !important; }
.highlight .gp,.highlight .w,.highlight .gh,.highlight .gu { color:#656555 !important; }
.highlight .cp,.highlight .s,.highlight .sb,.highlight .sc,.highlight .sd,.highlight .s2,.highlight .se,
.highlight .sh,.highlight .si,.highlight .ss,.highlight .kc,.highlight .kd,.highlight .kn,.highlight .kp,
.highlight .kr,.highlight .kt,.highlight .nt,.highlight .ow { color:#8fb28f !important; }
.highlight .gr,.highlight .gt,.highlight .m,.highlight .mf,.highlight .mh,.highlight .mi,
.highlight .mo,.highlight .sr,.highlight .il { color:#9c6363 !important; }
.highlight .no,.highlight .nv,.highlight .vc,.highlight .vg,.highlight .vi,.highlight .nd,
.highlight .sx { color:#dfaf8f !important; }