// Layout
//
// Styles for managing the structural hierarchy of the site.

.container {
  max-width: 38rem;
  padding:  0 1.5rem;
  margin: 0 auto;
}

.footer {
  margin: 0 0 1.5rem;

  &-links {
    margin: 0;
    list-style: none;
    text-align: right;

    li {
      display: inline-block;
      margin: 0 0.2rem;
      font-size: 0.7rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}


