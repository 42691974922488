// Masthead
//
// Super small header above the content for site name and short description.

.masthead {
  padding: 1rem 0;
  margin: 1.5rem 0 3rem;
  border-top: 0.5px solid var(--theme-color);
  border-bottom: 0.5px solid var(--theme-color);

  &-title {
    margin: 0;
    text-align: center;
    font-family: 'TitleRegular';
    font-weight: 400;
    font-size: 1.25rem;
    color: var(--theme-color);

    a {
      color: inherit;

      &:hover, &:focus {
        text-decoration: none;
      }
    }
  }
}
