// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

.page,
.post {
  margin-bottom: 3em;
  font-size: 0.9rem;

  li + li {
    margin-top: .25rem;
  }

  a {
    text-decoration: underline;
  }
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  font-size: 1.4rem;
  color: var(--theme-color);
  text-decoration: none;
}
.page-title,
.post-title {
  margin-top: 0;
}

// Meta data line below post title
.post-date {
  display: block;
  margin-top: -.5rem;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.post-footer,  .page-footer {
  margin-top: 3rem;
  text-align: center;
  color: var(--theme-color);
}
