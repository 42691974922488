// Pagination
//
// Super lightweight (HTML-wise) blog pagination. `span`s are provide for when
// there are no more previous or next posts to show.

.pagination {
  display: flex;
  justify-content: space-around;
  margin: 3rem 0;
  font-size: 0.8rem;
  color: var(--text-disabled);
}
