// Typography
//
// Headings, body text, lists, and other misc typographic elements.

h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-weight: 600;
  line-height: 1.25;
  color: var(--theme-color);
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2rem;
}

h2 {
  margin-top: 1rem;
  font-size: 1.5rem;
}

h3 {
  margin-top: 1.5rem;
  font-size: 1.25rem;
}

h4, h5, h6 {
  margin-top: 1rem;
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: var(--link-color);
  text-decoration: none;

  // `:focus` is linked to `:hover` for basic accessibility
  &:hover,
  &:focus {
    text-decoration: underline;
  }

  strong {
    color: inherit;
  }
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1rem;
  border-radius: 5px;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: .5rem;
}

hr {
  position: relative;
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #fff;
}

abbr {
  font-size: 85%;
  font-weight: bold;
  color: #555;
  text-transform: uppercase;

  &[title] {
    cursor: help;
    border-bottom: 1px dotted #e5e5e5;
  }
}

blockquote {
  padding: .5rem 1rem;
  margin: .8rem 0;
  color: #7a7a7a;
  border-left: .25rem solid #e5e5e5;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 30em) {
    padding-right: 5rem;
    padding-left: 1.25rem;
  }
}

table {
  margin-bottom: 1rem;
  width: 100%;
  font-size: 85%;
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
}

td,
th {
  padding: .25rem .5rem;
  border: 1px solid #e5e5e5;
}

th {
  text-align: left;
}

tbody tr:nth-child(odd) td,
tbody tr:nth-child(odd) th {
  background-color: #f9f9f9;
}

// Markdown footnotes
//
// See the example content post for an example.

// Footnote number within body text
a[href^="#fn:"],
// Back to footnote link
a[href^="#fnref:"] {
  display: inline-block;
  margin-left: .1rem;
  font-weight: bold;
}

// List of footnotes
.footnotes {
  margin-top: 2rem;
  font-size: 85%;
}