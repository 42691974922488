// Body resets
//
// Update the foundational and global aspects of the page.

@font-face {
  font-family: "BodyLight";
  src: url("/assets/fonts/Body-Light.woff") format("woff"),
}

@font-face {
  font-family: "TitleRegular";
  src: url("/assets/fonts/Title-Regular.woff2") format("woff2"),
}

:root {
  --root-font-size: 16px;
  --root-line-height: 1.5;
  --background-color: #F9F5E7;
  --text-color: #2a2a2a;
  --text-secondary: #474E68;
  --text-disabled: #ccc;
  --theme-color: #0078AA;
  --link-color: #0078AA;
  --code-color: #bf616a;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #2C3639;
    --text-color: #FCFFE7;
    --text-secondary: #9FC9F3;
    --text-disabled: #ccc;
    --theme-color: #B9FFF8;
    --link-color: #B9FFF8;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: 'BodyLight', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: var(--root-font-size);
  line-height: var(--root-line-height);

  @media (min-width:  38em) {
    font-size: 20px;
  }
}

body {
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
